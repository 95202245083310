<template>
  <div class="contianer">
    <h3>小宝 此后不再纠结</h3>
    <div class="lucky">
      <LuckyCanvas ref="lucky_canvas" @addHistory="addHistory" />
    </div>
    <div class="opera_box">
      <div class="opera">
        <van-button type="primary" size="small" @click="openDialog('toast')"
          >自定义</van-button
        >
      </div>
    </div>
    <div class="images">
      <h3>历史记录</h3>
      <div class="history" v-for="item in history" :key="item.time">
        {{ item.data }}
      </div>
      <div v-if="history.length == 0">暂无</div>
    </div>
    <CustomDialog @changeData="changeData" ref="custom_dialog" />
  </div>
</template>

<script setup>
import LuckyCanvas from "@/views/lucky_canvas/index.vue";
import CustomDialog from "@/views/components/custom_dialog.vue";
import { ref, reactive } from "vue";
const showToast = () => {};
const custom_dialog = ref(null);
const openDialog = () => {
  custom_dialog.value.openDialog();
};
const lucky_canvas = ref(null);

const changeData = (data) => {
  lucky_canvas.value.toogle(data);
};

//历史记录
const history = reactive([]);

const addHistory = (data) => {
  history.push(data);
};
</script>

<style lang="scss" scoped>
.contianer {
  padding-top: 30px;
  width: 100%;
  h3 {
    margin: 0;
  }
  .lucky {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .opera_box {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .opera {
      width: 300px;
      display: flex;
      justify-content: center;
    }
  }
  .images {
    margin-top: 60px;
    width: 100%;
    .history {
      margin-top: 10px;
    }
  }
}
</style>