<template>
  <LuckyWheel
    ref="myLucky"
    width="300px"
    height="300px"
    :prizes="prizes"
    :blocks="blocks"
    :buttons="buttons"
    @start="startCallback"
    @end="endCallback"
  />
</template>

<script>
import { showDialog } from "vant";

export default {
  data() {
    return {
      blocks: [{ padding: "13px", background: "#617df2" }],
      prizes: [
        { fonts: [{ text: "麦多馅饼", top: "10%" }], background: "#e9e8fe" },
        { fonts: [{ text: "手抓饼", top: "10%" }], background: "#b8c5f2" },
        { fonts: [{ text: "鸡蛋灌饼", top: "10%" }], background: "#e9e8fe" },
        { fonts: [{ text: "烤冷面", top: "10%" }], background: "#b8c5f2" },
        { fonts: [{ text: "酱香饼", top: "10%" }], background: "#e9e8fe" },
        { fonts: [{ text: "凉菜", top: "10%" }], background: "#b8c5f2" },
      ],
      buttons: [
        {
          radius: "35%",
          background: "#8a9bf3",
          pointer: true,
          fonts: [{ text: "恰饭", top: "-10px" }],
        },
      ],
    };
  },
  methods: {
    // 点击抽奖按钮会触发star回调
    startCallback() {
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play();
      // 模拟调用接口异步抽奖
      setTimeout(() => {
        // 假设后端返回的中奖索引是0
        const length = this.prizes.length;
        //取随机数  包含0  不包含
        var e = Math.floor(Math.random() * length);
        // 调用stop停止旋转并传递中奖索引
        this.$refs.myLucky.stop(e);
      }, 3000);
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log(prize.fonts[0].text);
      showDialog({
        title: prize.fonts[0].text,
        message: `宝宝要，好好吃饭 好好爱自己`,
      });
      this.$emit("addHistory", {
        data: prize.fonts[0].text,
        time: new Date().getTime(),
      });
    },
    //修改抽奖列表
    toogle(data) {
      this.prizes = data;
    },
  },
};
</script>