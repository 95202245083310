import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueLuckyCanvas from '@lucky-canvas/vue'
// 1. 引入你需要的组件

import { Form, Field, CellGroup, Dialog, Button } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
createApp(App)
    .use(router)
    .use(VueLuckyCanvas)
    .use(Button)
    .use(Dialog)
    .use(Form)
    .use(Field)
    .use(CellGroup)

    .mount("#app");
