<template>
  <div>
    <van-dialog
      v-model:show="show"
      type="digit"
      title="宝宝自己做"
      @close="closeDialog"
      @confirm="submitData"
      closeOnClickOverlay
      :showConfirmButton="false"
    >
      <van-form @failed="onFailed" @submit="onSubmit">
        <van-cell-group inset>
          <van-field v-model="num" type="number" placeholder="请输入个数">
            <template #button>
              <van-button size="small" @click="gen_form" type="primary"
                >确认</van-button
              >
            </template></van-field
          >
          <van-field
            v-for="(item, index) in form"
            :key="index"
            v-model="item.value"
            name="pattern"
            placeholder="类型1"
            :rules="[{ required: true, message: '宝宝，全部都要填哦' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            :disabled="!num"
            native-type="submit"
          >
            确定
          </van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, defineEmits } from "vue";
import { showDialog } from "vant";

const show = ref(false);
const openDialog = () => {
  show.value = true;
};

let form = reactive([]);

let num = ref("");

const onFailed = (errorInfo) => {
  console.log("failed", errorInfo);
};
const emit = defineEmits(["changeData"]);
const onSubmit = (values) => {
  const newData = form.map((item, index) => {
    if (index % 2 == 0) {
      return {
        fonts: [{ text: item.value, top: "10%" }],
        background: "#e9e8fe",
      };
    } else {
      return {
        fonts: [{ text: item.value, top: "10%" }],
        background: "#b8c5f2",
      };
    }
  });
  emit("changeData", newData);
  show.value = false;
};
const gen_form = () => {
  if (num.value < 1) {
    showDialog({ message: "宝宝 小于1就转不了了" });
    return;
  }
  form.length = 0;
  for (let i = 0; i < num.value; i++) {
    form.push({ value: "" });
  }
};
const closeDialog = () => {
  form.length = 0;
  num.value = "";
};

defineProps({
  msg: String,
});

// const emit = defineEmits(["closeDialog"]);
const submitData = () => {
  console.log("222222");
};
defineExpose({ openDialog });
</script>

<style lang="scss" scoped>
</style>